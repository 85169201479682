import { DockConfiguration } from '.';

import { JSONArray, JSONObject } from '../types';
import { runPipeline } from './middleware';
import { Utility, UtilitySchema } from './schemas/microfrontends';

export async function processConfiguration(
  config: JSONObject,
  accessToken: string,
): Promise<DockConfiguration> {
  return runPipeline(
    config['middleware'] as DockConfiguration['middleware'],
    config,
    accessToken,
  );
}

export const getUtilityModuleDefinitions = (microfrontends: JSONArray) => {
  return microfrontends
    .map((mfe) => {
      const utility = UtilitySchema.safeParse(mfe);
      if (utility.success) {
        return utility.data;
      }
      return null;
    })
    .filter((mfe): mfe is Utility => mfe !== null);
};
