import { JSONValue } from '../../types';

export async function fetchConfigurationData<T = JSONValue>(
  url: string,
  accessToken?: string,
): Promise<T | null> {
  if (!url) {
    console.error('no error specified');
    return null;
  }
  const headers = new Headers();
  if (accessToken) {
    headers.append('Authorization', `Bearer ${accessToken}`);
  }

  try {
    const response = await fetch(url, {
      headers: accessToken ? headers : undefined,
    });
    return (await response.json()) as T;
  } catch (error) {
    console.error('Failed to fetch configuration data:', error);
    return null;
  }
}
