import { z } from 'zod';

import { JSONObjectSchema } from './json';

export const FETCH_URL_KEY = '_url';
export const FETCH_MIDDLEWARE = 'fetchable-data';
export const FetchableSchema = z.object({
  [FETCH_URL_KEY]: z.string(),
  configuration: z.object({
    strategy: z
      .union([z.literal('value'), z.literal('merge')])
      .default('value'),
  }),
});

export type FetchableValue = z.infer<typeof FetchableSchema>;
export const MiddlewareSchema = z.object({
  _middleware: z.string(),
  configuration: JSONObjectSchema.optional(),
});
export const MiddlewareConfigurationSchema = z.object({
  id: z.string(),
  config: JSONObjectSchema.optional(),
});
export type Middleware = z.infer<typeof MiddlewareSchema>;
