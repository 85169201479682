import { Link, LinkNavigateParams } from '../links-manager';
import { BreadcrumbNode } from './types';

export interface BreadcrumbConfiguration {
  path?: string;
  url?: string;
  params?: LinkNavigateParams;
  text: string;
  icon?: string;
}

export class Breadcrumb implements BreadcrumbNode {
  public text: string;
  private params?: LinkNavigateParams;
  public icon?: string;
  constructor(
    config: BreadcrumbConfiguration,
    public link: Link,
  ) {
    this.text = config.text;
    this.params = config.params;
    this.icon = config.icon;
  }
  navigate(params?: LinkNavigateParams) {
    this.link.navigate(params || this.params);
  }
}
