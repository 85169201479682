import { ExternalLinkConfiguration, Link, PlainFlatObject } from '../types';
import { populateUrl } from './link.helpers';

export class ExternalLink implements Link {
  id: string;
  url: string;
  constructor(linkConfiguration: ExternalLinkConfiguration) {
    this.url = linkConfiguration.url;
    this.id = linkConfiguration.id;
  }

  navigate(params?: PlainFlatObject): void {
    window.location.assign(this.getUrl(params));
  }
  getUrl(params?: PlainFlatObject): string {
    return new URL(this.prepare(params)).href;
  }
  prepare(params?: PlainFlatObject): string {
    return populateUrl(this.url, params);
  }
  serialize() {
    return {
      url: this.url,
      id: this.id,
    };
  }
}
