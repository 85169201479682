export type NavigateTo = (
  link: string,
  fullReload?: boolean,
  newTab?: boolean,
) => void;
const navigateTo: NavigateTo = (
  link: string,
  fullReload: boolean = false,
  newTab: boolean = true,
) => {
  const isOpen =
    link.startsWith('http') || link.startsWith('https') || fullReload;
  const target = newTab ? '_blank' : '_self';

  if (isOpen) {
    window.open(link, target);
  } else {
    window.history.pushState({}, '', link);
  }
};
export const getNavigateTo = (isLegacy: boolean = false) => {
  return (link: string, fullReload = isLegacy, newTab = false) => {
    if (isLegacy) {
      navigateTo(link, isLegacy, newTab);
    } else {
      navigateTo(link, fullReload, newTab);
    }
  };
};
