import { z } from 'zod';

const NavigatorLinkSchema = z.object({
  icon: z.string(),
  text: z.string(),
  group: z.number().optional(),
  cardIcon: z.string(),
  isTop: z.boolean().default(false),
  isPinnable: z.boolean().default(true),
  isPinned: z.boolean().default(true),
  position: z.number().optional(),
  routingId: z.string().optional(),
});
const Navigator = NavigatorLinkSchema.extend({
  url: z.string(),
});
export const NavigatorApplicationLinkSchema = NavigatorLinkSchema.extend({
  applicationId: z.string(),
  path: z.string(),
});
export const NavigatorConfigurationSchema = z.object({
  links: z.array(NavigatorApplicationLinkSchema.or(Navigator)),
});
