import { PlainFlatObject } from '../types';

export const populateUrl = (
  path: string = '',
  params?: PlainFlatObject,
): string => {
  return path
    .split('/')
    .map((part) => {
      if (part.startsWith(':')) {
        const param = String(params && params[part.slice(1)]);
        return encodeURIComponent(param) || 'undefined';
      }
      return part;
    })
    .join('/');
};
