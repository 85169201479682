import { NavigatorConfiguration } from '../configuration/index';

export class NavigatorManager {
  private navigatorLinks: NavigatorConfiguration['links'] = [];
  private _cb: (() => void)[] = [];

  subscribe(cb: () => void): void {
    this._cb.push(cb);
  }

  private handleCb(): void {
    this._cb.forEach((cb) => cb());
  }

  setNavigatorLinks(newLinks: NavigatorConfiguration['links']): void {
    this.navigatorLinks = newLinks;
    this.handleCb();
  }

  addNavigatorLink(link: NavigatorConfiguration['links'][number]): void {
    this.navigatorLinks = [...this.navigatorLinks, link];
    this.handleCb();
  }

  getNavigatorLinks(): NavigatorConfiguration['links'] {
    return this.navigatorLinks.sort((a, b) => {
      const positionA =
        a.position !== undefined ? a.position : Number.POSITIVE_INFINITY;
      const positionB =
        b.position !== undefined ? b.position : Number.POSITIVE_INFINITY;

      return positionA - positionB;
    });
  }
}
