import { ApplicationMicrofrontend, DockConfiguration } from '../configuration';
import { ApplicationLink } from './applicationLink';
import { ApplicationLinkConfiguration } from './types';

export class LinksManager {
  private links: ApplicationLink[] = [];
  private dockConfiguration: DockConfiguration;

  constructor(dockConfig: DockConfiguration) {
    this.dockConfiguration = dockConfig;
    this._addApplicationLinks();
  }

  private _addApplicationLink(linkConfiguration: ApplicationLinkConfiguration) {
    this.links.push(new ApplicationLink(linkConfiguration));
  }

  private _addApplicationLinks = () => {
    this.dockConfiguration.microfrontends.forEach((mfe) => {
      if (isApplicationMicrofrontend(mfe)) {
        this._addApplicationLink({
          path: '',
          basePath: mfe.path,
          id: `${mfe.id}-main`,
          appId: mfe.id,
        });
      }
    });
  };

  getAllLinks() {
    return [...this.links];
  }

  getPublicLinkById(id: string) {
    return this.links.find((link) => link.id === id);
  }

  getPublicLinksByMicrofrontendId(mfeId: string) {
    return this.links.filter((link) => link.appId === mfeId);
  }
  addLinkfromMFE(
    mfeId: string,
    link: Omit<ApplicationLinkConfiguration, 'basePath' | 'appId'>,
  ) {
    const mfe = this.dockConfiguration.microfrontends.find(
      (mfe) => mfe.id === mfeId,
    );
    const basePath =
      mfe && isApplicationMicrofrontend(mfe) ? mfe.path : undefined;
    if (!mfe || !basePath) {
      console.error('not mfe available to add link');
      return;
    }
    this._addApplicationLink({
      appId: mfeId,
      basePath: basePath,
      path: link.path,
      id: link.id,
    });
  }
}

export const isApplicationMicrofrontend = (
  data: DockConfiguration['microfrontends'][number],
): data is ApplicationMicrofrontend =>
  !!data && 'path' in data && 'type' in data;
