import { z } from 'zod';

import { JSONValue } from '../../types';

const JSONValueSchema: z.ZodSchema<JSONValue> = z.lazy(() =>
  z.union([
    z.string(),
    z.number(),
    z.boolean(),
    z.null(),
    JSONObjectSchema,
    JSONArraySchema,
  ]),
);
export const JSONObjectSchema = z.record(JSONValueSchema);
export const JSONArraySchema = z.array(JSONValueSchema);
