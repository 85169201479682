import { NoneAuthConfiguration } from '../../configuration/schemas/auth';
import { UserInfo } from '../../types';
import { AuthService } from '../auth.service';

export class NoAuth implements AuthService {
  accessToken: string = 'No token';
  userInfo: UserInfo = {
    name: 'noname',
    email: 'mail@example.com',
  };
  static async create(
    _configuration: NoneAuthConfiguration,
  ): Promise<AuthService> {
    return new NoAuth();
  }
  async logout(): Promise<void> {
    console.warn('logged out');
  }
}
