import { Link } from '../links-manager';
import { ApplicationLink, composeLink } from '../links-manager/applicationLink';
import { ExternalLink } from '../links-manager/external-link';
import { Breadcrumb, BreadcrumbConfiguration } from './breadcrumb';

export class BreadcrumbFactory {
  constructor(private link?: ApplicationLink) {}

  build(config: BreadcrumbConfiguration): Breadcrumb {
    return new Breadcrumb(config, this.getLink(config));
  }

  private getLink(config: BreadcrumbConfiguration): Link {
    if (config.url) {
      return new ExternalLink({ id: 'external-breadcrumb', url: config.url });
    }
    if (!this.link) {
      throw new Error('Breadcrumb without a link');
    }

    if (config.path) {
      return composeLink(this.link, { path: config.path });
    }

    return new ApplicationLink(this.link.serialize());
  }
}
